@value BreakpointMedium from "../constants/shared.css";
@value TextBlue from "../constants/colors.css";

.title {
  margin: 0;
  font-size: 1.1em;
}

.container {
  display: flex;
  padding: 10px 20px;
}

.logo {
  height: 40px;
}

.text {
  margin-left: 20px;
  line-height: 40px;
  color: TextBlue;
}

@media screen and (max-width: BreakpointMedium) {
  .text {
    position: absolute;
    transform: translateX(-1000%);
  }
}
