@value BackgroundColor, PrimaryColor from "../constants/colors.css";
@value HeaderHeight: 60px;

.header {
  box-sizing: content-box;
  background-color: BackgroundColor;
  height: HeaderHeight;
  justify-content: center;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 3px solid PrimaryColor;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.logo {
  flex: 0 0 auto;
}

.nav {
  display: flex;
  margin: 0;
  padding: 0;
  line-height: HeaderHeight;
  list-style: none;
}

