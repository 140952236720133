@value FontFamily: 'Montserrat', sans-serif;

/* Generated at: https://google-webfonts-helper.herokuapp.com/fonts/montserrat?subsets=latin */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('../assets/fonts/montserrat-latin-regular.woff2') format('woff2'),
       url('../assets/fonts/montserrat-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('../assets/fonts/montserrat-latin-700.woff2') format('woff2'),
       url('../assets/fonts/montserrat-latin-700.woff') format('woff');
}
