@value BreakpointSmall from "../constants/shared.css";

.portrait {
  display: block;
  width: 350px;
  margin: 20px auto;
}

@media screen and (max-width: BreakpointSmall) {
  .portrait {
    width: 100%;
  }
}

