@value PrimaryColor from "../constants/colors.css";
@value BreakpointSmall from "../constants/shared.css";
@value HeaderHeight from "./NavHeader.css";

.text {
  font-weight: bold;
  display: block;
  height: HeaderHeight;
  padding: 0 20px;
}

/* TODO: style if active route */
.active {}

@media screen and (min-width: BreakpointSmall) {
  .text {
    padding: 0 40px;
  }
}
