@import-normalize;

@value BackgroundColor, TextColor from "./constants/colors.css";
@value FontFamily from "./constants/fonts.css";

@value FontBaseSize: 16px;
@value FontScale: 4;

body,
html {
  background-color: BackgroundColor;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
}

* {
  box-sizing: inherit;
}

html {
  font-family: FontFamily;
  line-height: 1.6em;
  color: TextColor;
  font-size: FontBaseSize;
}

code {
  font-family: Consolas, "Courier New", monospace;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(FontBaseSize + FontScale * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: calc(FontBaseSize + FontScale * 1px);
  }
}
