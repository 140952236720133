@value HeaderHeight from "./NavHeader.css";

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main {
  margin-top: HeaderHeight;
  position: relative;
  flex-grow: 1;
  overflow-x: auto;
}
