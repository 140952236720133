@value TextBlue, PrimaryColor from "../constants/colors.css";

.link {
  text-decoration: none;
  color: TextBlue;
}

.link:hover, .link:active {
  color: PrimaryColor;
}
