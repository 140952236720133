@value TransitionTime from "../../constants/shared.css";

.route {
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1em;
  max-width: 1000px;
  margin: 0 auto;
  transition: opacity TransitionTime, transform TransitionTime;
}

/* ----------- Left ------------ */

.leftEnter {
  position: absolute;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.leftEnterActive, .leftExit {
  position: absolute;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.leftExitActive {
  position: absolute;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

/* ----------- Right ------------ */

.rightEnter {
  position: absolute;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.rightEnterActive, .rightExit {
  position: absolute;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.rightExitActive {
  position: absolute;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

/* ----------- Opacity ------------ */

.opacityEnter {
  position: absolute;
  opacity: 0;
}
.opacityEnterActive, .opacityExit {
  position: absolute;
  opacity: 1;
}
.opacityExitActive {
  position: absolute;
  opacity: 0;
}

